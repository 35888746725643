<template>
  <el-dialog
      :visible.sync="delVideoStatus"
      width="480rem"
      :show-close="false"
      class="dialog"
    >
      <div class="dialog_box flex-col">
        <span class="dialog_txt" v-if="type == 'video'">确定删除此视频？</span>
        <span class="dialog_txt" v-if="type == 'canva'">确定删除此模板？</span>
        <div slot="footer" class="dialog-footer flex-row justify-between">
          <div @click="delVideoStatus = false">取消</div>
          <div @click="onOk">删除</div>
        </div>
      </div>
    </el-dialog>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      delVideoStatus: false,
    };
  },
  created() {},
  computed: {},
  methods: {
    // 打开弹窗
    openDialog() {
      this.delVideoStatus = true;
    },
    // 点击取消订单
    onOk() {
      this.$emit("onOkDel");
      this.delVideoStatus = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .el-icon-close {
    position: absolute;
    top: -135%;
    right: -142%;
    color: #fff;
    font-size: 36rem;
    cursor: pointer;
  }
  .dialog_box {
    .dialog_txt {
      font-size: 18rem;
      color: #252927;
      line-height: 25rem;
      text-align: center;
    }
    .dialog-footer {
      width: 100%;
      margin-top: 13%;
      div {
        width: 128rem;
        height: 42rem;
        line-height: 42rem;
        border-radius: 25rem;
        text-align: center;
        color: #2bb3e3;
        background: #ffffff;
        border-radius: 25rem;
        border: 1rem solid #32c3f6;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          background: linear-gradient(225deg, #4facfe 0%, #28dae3 100%);
          color: #fff;
        }
      }
    }
  }
}
::v-deep .el-dialog {
  background: rgba(255, 255, 255, 0.95);
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body {
  padding: 12.5% 20% 10% 20%;
}
</style>
