<template>
  <div
    class="page flex-col"
    ref="page"
    :style="
      hostname == 'demo.daping.cn'
        ? 'height: calc(100vh - 120rem)'
        : 'height: calc(100vh - 70rem)'
    "
    @click.stop="onCloseShowDialog"
    v-infinite-scroll="videoListLoad"
    infinite-scroll-distance="1"
    infinite-scroll-immediate="false"
  >
    <div class="flex-row justify-center">
      <menuTabs style="margin-right: 26rem" />
      <div class="right-view flex-col align-center">
        <div
          class="adList flex-row flex-wrap"
          v-if="isBuyVip && videoList.length">
          <div class="add-item flex-col">
            <div class="item-top flex-col">
              <div
                @click="onClick_create"
                class="thumbnailUrl flex-row justify-center align-center"
              >
                <img class="img" src="@/assets/icon/add_icon.png" />
              </div>
              <div class="desc1 flex-row align-center">
                <span>创建新模板</span>
                <el-popover
                  class="popover"
                  placement="right"
                  trigger="hover"
                  :visible-arrow="false"
                  popper-class="canvaPopover"
                >
                  <div class="popover-content flex-col justify-between">
                    <span
                      >大屏时代已为您接入「Canva可画（高级版）」，您无需另外注册「Canva可画（高级版）」，也无需另外付费，
                      只需在大屏时代网站内即可创作漂亮的广告视频，您也可以将所创作的视频下载到电脑本地，用于其它用途。</span
                    >
                    <span style="margin-top: 20rem"
                      >「Canva可画高级版」的精彩内容：</span
                    >
                    <span>* 超过7000万高级版图片、视频、音频和插画</span>
                    <span>* 无限使用各种功能、文件夹和付费内容</span>
                    <span>* 超过10万付费和免费模版，每日更新</span>
                    <span>* 无限量使用自动调整尺寸功能</span>
                    <span>* 1TB云存储空间</span>
                  </div>
                  <div slot="reference" class="reference flex-row align-center">
                    <svg-icon
                      class="service-svg"
                      iconClass="overlay"
                    ></svg-icon>
                  </div>
                </el-popover>
              </div>
              <div class="desc2">
                <span>默认：1920 * 1080 px</span>
              </div>
            </div>
          </div>
          <div
            class="adList-item flex-col"
            v-for="(item, index) in videoList"
            :key="index"
          >
            <div class="item-top flex-col">
              <div
                class="thumbnailUrl flex-col"
                @mouseenter="temPlay(index)"
                @mouseleave="temPause(index)"
                @click="onClickAgainEdit(item.designId)"
              >
                <video
                  oncontextmenu="return false;"
                  :id="'temVideo' + index"
                  class="video"
                  :poster="item.videoThumbnailUrl"
                  loop
                  muted
                  preload="auto"
                >
                  <source :src="item.videoUrl" type="video/mp4" />
                </video>
                <img
                  v-if="item.muted"
                  @click.stop="changeMute(item, index)"
                  class="icon"
                  src="@/assets/img/mute.png"
                />
                <img
                  v-else
                  class="icon"
                  @click.stop="changeMute(item, index)"
                  src="@/assets/img/noMute.png"
                />
                <div
                  :class="{
                    'edit-view': true,
                    'edit-view-show': item.showDialog ? true : false,
                  }"
                  @click.stop="onChangeShowDialog(item)"
                >
                  •••
                </div>
              </div>
              <div class="desc1">
                <span
                  >{{ item.resolvingPower }}像素，宽高比
                  {{ item.aspectRatio }}</span
                >
              </div>
              <div
                class="item-dialog flex-col align-center"
                v-if="item.showDialog"
                @click.stop="item.showDialog = true"
              >
                <div
                  class="dialog-top flex-row justify-center align-center"
                  @click.stop="delVideo(item, index)"
                >
                  <img src="@/assets/icon/red_del.png" />
                  <span>删除此模板</span>
                </div>
                <span class="desc"
                  >最后编辑于：{{ item.lastDesignEditTime | dateFormat }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="isBuyVip && !videoList.length"
          class="noData flex-col justify-center align-center"
        >
          <span>您还没有创建过广告模版</span>
          <div
            class="launch-btn flex-col"
            id="primary-btn"
            @click="onClick_create"
          >
            去创建
          </div>
        </div>
        <div v-if="!videoList.length" class="explain-view flex-col">
          <div class="title-view">
            <div class="border-left"></div>
            <div class="title">
              <a href="https://www.canva.cn/pro/#pricing" target="_blank"
                >「Canva可画（高级版）」</a
              >，适合个人创作者，个体商家和自由设计师，可以无限量使用「Canva可画」全部高级版内容，轻松高效创作专业水准的设计。
            </div>
          </div>
        </div>
        <div v-if="!videoList.length" class="explain-view flex-col">
          <div class="title-view">
            <div class="border-left"></div>
            <div class="title" v-if="isBuyVip">
              大屏时代已为您接入「Canva可画高级版」，您无需另外注册「Canva可画」，
              也无需另外付费，只需在大屏时代网站内即可创作漂亮的广告视频，您也可以将所创作的视频下载到电脑本地，用于其它用途。
            </div>
            <div class="title" v-else>
              大屏时代已为企业套餐用户接入「Canva可画高级版」，您无需另外注册「Canva可画」，
              也无需另外付费，只需在大屏时代网站内即可创作漂亮的广告视频，您也可以将所创作的视频下载到电脑本地，用于其它用途。
            </div>
          </div>
        </div>
        <div v-if="!videoList.length" class="explain-view flex-col">
          <div class="title-view">
            <div class="border-left"></div>
            <div class="title">「Canva可画（高级版）」的精彩内容：</div>
          </div>
          <div class="content flex-col">
            <span>* 超过7000万高级版图片、视频、音频和插画</span>
            <span>* 无限使用各种功能、文件夹和付费内容</span>
            <span>* 超过10万付费和免费模版，每日更新</span>
            <span>* 无限量使用自动调整尺寸功能</span>
            <span>* 1TB云存储空间</span>
          </div>
        </div>
        <div
          v-if="!isBuyVip"
          class="bottom-btn"
          id="primary-btn"
          @click="goProductPrice"
        >
          了解「大屏时代」企业套餐
        </div>
      </div>
    </div>
    <Footer></Footer>
    <el-backtop target=".page" :visibility-height="350">
      <div class="backtop flex-row justify-center align-center">
        <svg-icon class="gotop-svg" iconClass="gotop"></svg-icon>
      </div>
    </el-backtop>
    <del-video-dialog
      ref="refDelVideo"
      type="canva"
      @onOkDel="onOkDel"
    ></del-video-dialog>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import menuTabs from "@/components/menuTabs.vue";
import { getUserInfo, listMyVideo, deleteMyDesignVideo } from "@/api/request";
import Footer from "@/layout/components/footer.vue";
import delVideoDialog from "./components/delVideoDialog.vue";

export default {
  components: {
    menuTabs,
    delVideoDialog,
    Footer,
  },
  data() {
    return {
      packageType: null,
      videoList: [],
      alltemplateList: [],
      editCanvaDialogData: {},
      currentDelDesignId: "",
      currentDelDesignIndex: 0,
      currentPages: 0,
      pageParams: {
        is_design: true,
        pageLimit: {
          limit: 11,
          page: 1,
        },
      },
      isBuyVip: false
    };
  },
  created() {
    this.getUserInfo();
  },
  mounted() {},
  computed: {
    hostname() {
      return window.location.hostname;
    },
  },
  methods: {
    // 查询用户信息
    getUserInfo() {
      getUserInfo({}).then((res) => {
        this.isBuyVip = res.userInfo.businessInfo.isBuyVip;
        this.getListMyVideo();
        this.$store.dispatch("setUserInfoActions", res.userInfo);
      });
    },
    // 获取历史投放
    getListMyVideo() {
      listMyVideo(this.pageParams)
        .then((res) => {
          this.currentPages = res.pageResult.pages;
          this.videoList = [...this.videoList, ...res.videoList];
          if (this.videoList.length) {
            this.$nextTick(() => {
              this.videoList.forEach((item, index) => {
                item.muted = true;
                item.showDialog = false;
                // 计算视频的分辨率和宽高比
                const videoElement = document.getElementById(
                  `temVideo${index}`
                );
                videoElement.addEventListener("loadedmetadata", () => {
                  // console.log(videoElement.videoWidth, videoElement.videoHeight);
                  const maxNumber = this.maxNumber(
                    videoElement.videoWidth,
                    videoElement.videoHeight
                  ); // 宽和高的最大公约数
                  item.resolvingPower = `${videoElement.videoWidth} × ${videoElement.videoHeight}`;
                  item.aspectRatio = `${
                    videoElement.videoWidth / maxNumber
                  } : ${videoElement.videoHeight / maxNumber}`;
                  // 强制更新组件
                  this.$forceUpdate();
                });
              });
            });
          }
        })
        .catch(() => {
          if (this.pageParams.pageLimit.page > 1) {
            this.pageParams.pageLimit.page--;
          }
        });
    },
    // 计算最大公约数
    maxNumber(a, b) {
      if (a > 0 && b > 0) {
        while (b !== 0) {
          const temp = b;
          b = a % b;
          a = temp;
        }
        return a;
      }
      return 1;
    },
    temPlay(index) {
      // 获取视频标签
      const video = document.getElementById(`temVideo${index}`);
      // console.log("视频时长:", video.duration);
      // 播放视频
      video.play();
    },
    temPause(index) {
      // 获取视频标签
      const video = document.getElementById(`temVideo${index}`);
      // 停止播放
      video.pause();
      setTimeout(() => {
        video.load();
      }, 10);
    },
    // 静音/取消静音
    changeMute(item, index) {
      const videoElement = document.getElementById(`temVideo${index}`);
      videoElement.muted = !videoElement.muted;
      item.muted = videoElement.muted;
      // 强制更新组件
      this.$forceUpdate();
    },
    onChangeShowDialog(item) {
      console.log(item);
      this.videoList.forEach((items) => {
        items.showDialog = false;
      });
      item.showDialog = true;
      // 强制更新组件
      this.$forceUpdate();
    },
    onCloseShowDialog() {
      this.videoList.forEach((items) => {
        items.showDialog = false;
      });
      // 强制更新组件
      this.$forceUpdate();
    },
    delVideo(item, index) {
      this.currentDelDesignId = item.designId;
      this.currentDelDesignIndex = index;
      this.$refs.refDelVideo.openDialog();
    },
    onOkDel() {
      deleteMyDesignVideo({
        design_id: this.currentDelDesignId,
      }).then(() => {
        this.$message({
          type: "success",
          center: true,
          message: "删除成功",
        });
        this.videoList.splice(this.currentDelDesignIndex, 1); // 手动删除页面上的该视频
      });
    },
    videoListLoad() {
      if (this.pageParams.pageLimit.page < this.currentPages) {
        this.pageParams.pageLimit.page++;
        console.log(
          "触发了下拉到底，进行分页请求，请求页码为",
          this.pageParams.pageLimit.page
        );
        this.getListMyVideo();
      }
    },
    onClick_create() {
      this.$canvaUtil.onClickCreate(true);
    },
    onClickAgainEdit(designId) {
      console.log(designId);
      this.$canvaUtil.onClickAgainEdit(designId, true);
    },
    editCanvaOk() {},
    goProductPrice() {
      this.$router.push({ name: "ProductPrice" });
    },
  },
};
</script>

<style lang="scss" scoped>
.popover {
  margin-left: 3rem;
  .popover-content {
    span {
      font-size: 14rem;
      color: #252927;
      line-height: 20rem;
    }
  }
  .reference {
    cursor: pointer;
    .service-svg {
      color: #696969;
      width: 20rem;
      height: 20rem;
    }
    &:hover {
      .service-svg {
        color: #32c3f6;
      }
    }
  }
}
.page {
  width: 100%;
  background-color: #fff;
  padding-top: 60rem;
  overflow-y: auto;
  margin: 0 auto;
  .el-backtop {
    width: 50rem;
    height: 50rem;
    right: 40rem !important;
    bottom: 240rem !important;
    box-shadow: none;
    background: #fff;
    border: 1rem solid #dedede;
    .el-icon-arrow-up {
      transition: all 0.3s;
      color: #42b6fb;
    }
    .gotop-svg {
      width: 48rem;
      height: 48rem;
      color: #42b6fb;
    }
    &:hover {
      background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
      border: none;
      .el-icon-arrow-up {
        color: #fff;
      }
      .gotop-svg {
        color: #fff;
      }
    }
  }
  .right-view {
    width: 1060rem;
    margin-bottom: 60rem;
    min-height: calc(100vh - 196rem);
    .adList {
      width: 100%;
      .adList-item {
        width: 322rem;
        margin: 0 47rem 40rem 0;
        &:nth-child(3n) {
          margin-right: 0;
        }
        .item-top {
          width: 100%;
          position: relative;
          .thumbnailUrl {
            z-index: 94;
            width: 100%;
            background: #333333;
            position: relative;
            border-radius: 8rem;

            cursor: pointer;
            &:hover {
              .edit-view,
              .icon {
                display: block;
              }
            }

            .video {
              width: 100%;
              max-height: 187rem;
              border-radius: 6rem 6rem 0 0;
              z-index: 1;
              border-radius: 8rem;
              object-fit: contain;
            }
            .icon {
              width: 28rem;
              height: 28rem;
              position: absolute;
              bottom: 15rem;
              right: 15rem;
              display: none;
              z-index: 2;
            }
            .edit-view {
              padding: 2rem 4rem;
              position: absolute;
              top: 10rem;
              right: 15rem;
              text-align: center;
              line-height: 12rem;
              background-color: rgba(0, 0, 0, 0.5);
              font-size: 14rem;
              color: #ffffff;
              z-index: 2;
              border-radius: 4rem;
              display: none;
              &:hover {
                background: linear-gradient(225deg, #4facfe 0%, #28dae3 100%);
              }
              &:active {
                background: linear-gradient(225deg, #4facfe 0%, #28dae3 100%);
              }
            }
            .edit-view-show {
              display: block;
              background: linear-gradient(225deg, #4facfe 0%, #28dae3 100%);
            }
          }

          .desc1 {
            margin: 12rem 0 0 12rem;
            position: relative;
            cursor: pointer;
            span {
              font-size: 14rem;
              font-weight: 500;
              font-family: PingFang-SC-Medium, PingFang-SC;
              line-height: 20rem;
              color: #252927;
              display: inline-block;
              width: 290rem;
            }
            .edit-svg {
              display: none;
              color: #b0b0b0;
              width: 14rem;
              height: 14rem;
              position: absolute;
              right: 0;
              top: 2rem;
            }
            &:hover {
              .edit-svg {
                display: block;
              }
            }
          }

          .desc2 {
            margin: 5rem 0 0 12rem;

            span {
              font-size: 14rem;
              font-family: PingFang-SC-Medium, PingFang-SC;
              font-weight: 500;
              line-height: 20rem;
              color: #969898;
            }
          }
          .item-dialog {
            width: max-content;
            padding: 12rem 16rem;
            background: #ffffff;
            box-shadow: 0px 0px 15rem 0px rgba(0, 0, 0, 0.05);
            border-radius: 10rem;
            position: absolute;
            top: 32rem;
            left: 280rem;
            z-index: 999;
            .dialog-top {
              width: 100%;
              padding-bottom: 8rem;
              border-bottom: 1px solid #d8d8d8;
              cursor: pointer;
              img {
                width: 20rem;
                height: 20rem;
              }
              span {
                font-size: 14rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #f52f3e;
                line-height: 20rem;
                margin-left: 5rem;
              }
            }
            .desc {
              font-size: 12rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #252927;
              line-height: 17rem;
              margin-top: 8rem;
            }
          }
        }
      }
      .add-item {
        width: 322rem;
        margin: 0 47rem 40rem 0;
        .item-top {
          width: 100%;
          height: 256rem;
          overflow: hidden;
          .thumbnailUrl {
            z-index: 94;
            width: 100%;
            height: 187rem;
            background: #f6f7f9;
            position: relative;
            border-radius: 8rem;
            cursor: pointer;

            .img {
              width: 70rem;
              height: 70rem;
            }
            .icon {
              width: 28rem;
              height: 28rem;
              position: absolute;
              bottom: 15rem;
              right: 15rem;
              display: none;
            }
            &:hover {
              .icon {
                display: block;
              }
            }
          }

          .desc1 {
            margin: 12rem 0 0 12rem;
            span {
              font-size: 14rem;
              font-weight: 500;
              font-family: PingFang-SC-Medium, PingFang-SC;
              line-height: 20rem;
              color: #2bb3e3;
              margin-right: 5rem;
            }
          }

          .desc2 {
            margin: 5rem 0 0 12rem;

            span {
              font-size: 14rem;
              font-family: PingFang-SC-Medium, PingFang-SC;
              font-weight: 500;
              line-height: 20rem;
              color: #969898;
            }
          }
        }
      }
    }
    .noData {
      width: 1060rem;
      height: 214rem;
      background: #f8f8f8;
      border-radius: 8rem;
      margin-bottom: 40rem;
      span {
        font-size: 22rem;
        color: #252927;
        line-height: 30rem;
      }
      .launch-btn {
        width: 168rem;
        height: 44rem;
        text-align: center;
        line-height: 44rem;
        border-radius: 25rem;
        cursor: pointer;
        transition: all 0.3s;
        margin-top: 40rem;
        font-size: 18rem !important;
        color: #ffffff;
      }
    }
    .explain-view {
      width: 1060rem;
      background: #f8f8f8;
      border-radius: 8rem;
      padding: 18rem 26rem;
      margin-top: 28rem;
      .title-view {
        position: relative;
        .border-left {
          width: 3rem;
          height: 18rem;
          background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
          position: absolute;
          left: 0;
          top: 4rem;
        }
        .title {
          font-size: 18rem;
          color: #252927;
          line-height: 25rem;
          margin-left: 17rem;
          a {
            color: rgba(43, 179, 227, 1);
            text-decoration: none;
          }
        }
      }
      .content {
        padding-left: 18rem;
        span {
          font-size: 18rem;
          color: #252927;
          line-height: 25rem;
        }
      }
    }
    .bottom-btn {
      background: linear-gradient(225deg, #4facfe 0%, #28dae3 100%);
      border-radius: 25rem;
      padding: 10rem 25rem;
      font-size: 18rem;
      color: #ffffff;
      margin-top: 40rem;
    }
  }
}
</style>
